import React, { createContext, useState } from "react";
import Styled from "styled-components";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Toast from "./components/Toast";
import { useSelector, useDispatch } from "react-redux";
import { toggleThemeBar } from "./redux/themeReducer";
import VerifyEmail from "./pages/VerifyEmail";
import ReactGA from "react-ga";
import Reset from "./pages/Reset";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import CookiesPoilcy from "./pages/CookiesPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import LandingPage from "./pages/LandingPage";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home";

//parent container
const Container = Styled.div`
height:100vh;
background-color:${(props) => props.bgColor};`;

// context provider
export const ContextProvider = createContext(null);
const TRACKING_ID = "G-XT07ZWE2PT";
ReactGA.initialize(TRACKING_ID);
ReactGA.initialize();
export const App = () => {
  const user = useSelector((state) => state.user.currentUser);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <LandingPage />,
    },
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/profile",
      element: user === null ? <Navigate redirect to="/login" /> : <Home />,
    },
    {
      path: "/doc/:id",
      element: <Home />,
    },
    {
      path: "/password-reset",
      element: user === null ? <Navigate redirect to="/login" /> : <Reset />,
    },
    {
      path: "/verify-email",
      element: <VerifyEmail />,
    },
    {
      path: "/liked",
      element: user === null ? <Navigate redirect to="/login" /> : <Home />,
    },
    {
      path: "/mynotes",
      element: user === null ? <Navigate redirect to="/login" /> : <Home />,
    },
    {
      path: "/purchased",
      element: user === null ? <Navigate redirect to="/login" /> : <Home />,
    },
    {
      path: "/cart",
      element: user === null ? <Navigate redirect to="/login" /> : <Home />,
    },
    {
      path: "/orders",
      element: user === null ? <Navigate redirect to="/login" /> : <Home />,
    },
    {
      path: "/note/:id",
      element: user === null ? <Navigate redirect to="/login" /> : <Home />,
    },
    {
      path: "/notes/:id",
      element: user === null ? <Navigate redirect to="/login" /> : <Home />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    { path: "/terms-conditions", element: <TermsConditions /> },
    { path: "/refund-policy", element: <RefundPolicy /> },
    { path: "/cookies-policy", element: <CookiesPoilcy /> },
    {
      path: "*",
      element: <div>Route not found</div>,
    },
  ]);
  const theme = useSelector((state) => state.theme.toggle);

  const dispatch = useDispatch();
  const [alert, setAlert] = useState({
    visible: 0,
    type: "success",
    message: "",
    transform: "translateY(25px)",
    zIndex: "-1",
  });
  const colorTheme =
    theme === "light"
      ? {
          backPri: "#E5E5E5",
          backSec: "#FFFFFF",
          fontPri: "#000000",
          fontSec: "grey",
          mainColor: "#387ADF",
        }
      : {
          backPri: "#121212",
          backSec: "#202020",
          fontPri: "#FFFFFF",
          fontSec: "#F8f6fa",
          mainColor: "#387ADF",
        };

  const ToggleTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    dispatch(toggleThemeBar(newTheme));
  };
  var x;
  var y;
  let z;
  const [toast, setToast] = useState(false);
  const setSecondNotification = async (type, message) => {
    clearTimeout(x);
    setAlert({
      visible: 1,
      type: type,
      message: message,
      transform: "translateY(0px)",
      zIndex: "1",
    });

    x = setTimeout(() => {
      removeNotification();
      clearTimeout(z);
      z = setTimeout(() => {
        setToast(false);
      }, 400);
    }, 1500);
  };
  const setNotification = async (type, message) => {
    clearTimeout(y);
    clearTimeout(z);
    setToast(true);
    y = setTimeout(() => {
      setSecondNotification(type, message);
    }, 100);
  };
  const removeNotification = () => {
    setAlert((item) => {
      return { ...item, transform: "translateY(25px)" };
    });
  };
  return (
    <ContextProvider.Provider
      value={{
        theme,
        ToggleTheme,
        alert,
        setNotification,
        removeNotification,
        colorTheme,
      }}
    >
      <Container bgColor={colorTheme.backPri}>
        <RouterProvider router={router} />
        {toast && <Toast />}
      </Container>
    </ContextProvider.Provider>
  );
};

export default App;
