import React, { useContext, useState, useEffect } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../App";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/loadReducer";
import { useSelector } from "react-redux";
import { Mobile } from "../responsive";
import { loginSuccess, logout } from "../redux/userReducer";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import ThumbUpRoundedIcon from "@mui/icons-material/ThumbUpRounded";
import Buffering from "../components/General/Buffering";
import UnitItem from "../components/General/UnitItem";
import PaymentRoundedIcon from "@mui/icons-material/PaymentRounded";
import Axios from "axios";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
const Container = Styled.div`
  padding-left:7%;
  padding-right:7%;
  color: ${(item) => item.theme.fontPri};
  ${Mobile({ paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px" })};
`;

const Wrapper = Styled.div`
  
`;
const Top = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-items:flex-start;
  ${Mobile({ flexDirection: "column" })};
`;
const Bottom = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:column;

  padding:0px 100px;
  box-sizing:border-box;
  ${Mobile({ width: "100%", padding: "5px" })};
  `;

const Left = Styled.div`

  flex: 1;
  display:flex;
  align-items:center;
  justify-content:flex-end;
  ${Mobile({ justifyContent: "center", width: "100%" })};
`;

const ImageContainer = Styled.div`
  width: 70%;
  padding:20px;
  box-sizing:border-box;
  ${Mobile({ width: "100%", padding: "5px" })};
`;

const Image = Styled.img`
  width: 100%;
  border-radius: 20px;
`;

const Right = Styled.div`
  flex: 1;
  color: ${(item) => item.theme.fontPri};
`;

const ActionContainer = Styled.div`
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  padding:30px;
  ${Mobile({ padding: "5px" })};
`;

const Title = Styled.h1`
  margin:5px;
  color: ${(item) => item.theme.fontPri};
`;

const Subtitle = Styled.h2`
  margin:5px;
  color: ${(item) => item.theme.fontSec};
`;

const Description = Styled.p`
  font-size: 20px;
  color: ${(item) => item.theme.fontSec};
  text-align:justify;
`;

const ButtonContainer = Styled.div`
  width:100%;
  display:flex;
  
`;

const Button = Styled.button`
  padding:15px;
  background-color: ${(item) => item.theme.mainColor};
  color: white;
  border: none;
  width:80%;
  border-radius: 20px;
  cursor: pointer;
  font-size:16px;
  display:flex;
  align-items:center;
  justify-content:center;
  transition: transform 0.2s ease;
  &:active {
  transform: scale(0.95); /* Shrinks the button slightly */
  };
  ${Mobile({ width: "100%" })};
`;

const CartContainer = Styled.div`
  flex:1;
  padding:5px;
`;
const BuyContainer = Styled.div`
    padding:5px;
    flex:1;`;
const HomeCard = () => {
  const { colorTheme, setNotification } = useContext(ContextProvider);
  const loader = useSelector((state) => state.load.toggle);
  const [paymentLoad, setPaymentLoad] = useState(false);
  const user = useSelector((state) => state.user.currentUser);
  const location = useLocation();
  const activeNote = useSelector((state) => state.activenote);
  const docId = location.pathname.split("/")[2];
  const isLiked = user ? user?.liked?.some((item) => item.id === docId) : false;
  const isPurchased = user
    ? user?.purchased?.some((item) => item.id === docId)
    : false;
  const [likeLoad, setLikeLoad] = useState(false);
  const [docData, setDocData] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAddedToCart = user
    ? user?.cart?.some((item) => item.id === docId)
    : false;

  const IconStyle = {
    height: "30px",
    width: "30px",
    paddingRight: "5px",
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading(true));
      try {
        const res = await Axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/docs/getDocsById/${docId}`
        );
        setDocData(res.data);
      } catch (error) {
        setNotification("failure", error.response?.data?.message);
      }
      dispatch(setLoading(false));
    };
    fetchData();
  }, []);
  const handleLike = async () => {
    if (!user) {
      setNotification("failure", "Please login to continue");
      return;
    }
    if (user && user.liked.includes(docId)) {
      setNotification("info", "Document already liked");
      return;
    }
    setLikeLoad(true);
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/docs/likeDocById/${docId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
      console.log(response.data);
      dispatch(loginSuccess({ ...user, liked: response?.data?.liked }));
      setNotification("success", response.data.message);
    } catch (error) {
      if (error?.response?.status === 411) {
        setNotification("failure", "Sessions Exceeded! Please login again");
        dispatch(logout());
        navigate("/login");
        return;
      }
      setNotification("failure", error?.response?.data?.message);
    }
    setLikeLoad(false);
  };
  const handleAddToCart = async () => {
    if (!user) {
      setNotification("failure", "Please Login to Continue");
      return;
    }
    if (user && user.cart.some((item) => item.id === docId)) {
      setNotification("success", "Document already in cart");
      return;
    }

    try {
      const { data } = await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/addDocToCart`,
        { docId },
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );

      dispatch(loginSuccess({ ...user, cart: data.cart }));
      setNotification("success", "Added to cart");
    } catch (error) {
      if (error?.response?.status === 411) {
        setNotification("failure", "Sessions Exceeded! Please login again");
        dispatch(logout());
        navigate("/login");
        return;
      }
      setNotification(
        "failure",
        error.response?.data?.message || "Document already in cart"
      );
    }
  };

  return (
    <Container theme={colorTheme}>
      {loader === false ? (
        <Wrapper>
          <Top>
            <Left theme={colorTheme}>
              <ImageContainer>
                <Image src={docData.thumbnail} alt={docData.title} />
              </ImageContainer>
            </Left>
            <Right theme={colorTheme}>
              <ActionContainer>
                <Title>{docData.docTitle}</Title>
                <Subtitle>{docData.subtitle}</Subtitle>
                <Description>{docData.description}</Description>
                <ButtonContainer>
                  {!isPurchased ? (
                    <CartContainer>
                      <Button theme={colorTheme} onClick={handleAddToCart}>
                        <ShoppingCartRoundedIcon style={IconStyle} />
                        {isAddedToCart ? "Added" : "Add"}
                      </Button>
                    </CartContainer>
                  ) : (
                    <BuyContainer>
                      <Button paymentLoad={paymentLoad} theme={colorTheme}>
                        <PaymentRoundedIcon style={IconStyle} />
                        Purchased
                      </Button>
                    </BuyContainer>
                  )}

                  <CartContainer>
                    <Button
                      theme={colorTheme}
                      likeLoad={likeLoad}
                      onClick={handleLike}
                    >
                      {isLiked ? (
                        <ThumbUpRoundedIcon style={IconStyle} />
                      ) : (
                        <ThumbUpOutlinedIcon style={IconStyle} />
                      )}
                      {isLiked ? "Liked" : "Like"}
                    </Button>
                  </CartContainer>
                </ButtonContainer>
              </ActionContainer>
            </Right>
          </Top>
          <Bottom>
            {docData?.chapters?.map((item) => (
              <UnitItem
                unitTitle={item.id.unitTitle}
                subtitle={item.id.subtitle}
                thumbnail={docData.thumbnail}
                id={item.id._id}
              />
            ))}
          </Bottom>
        </Wrapper>
      ) : (
        <Buffering />
      )}
    </Container>
  );
};

export default HomeCard;
