import React, { useContext, useState, useEffect } from "react";
import Styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Mobile } from "../responsive";
import { ContextProvider } from "../App";
import { logout } from "../redux/userReducer";
import { useNavigate } from "react-router-dom";
import RenderBlog from "../components/RenderBlog";
import { unsetActiveNote } from "../redux/activeNoteReducer";
import Notes from "../components/Notes";
import Axios from "axios";

import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { loginSuccess } from "../redux/userReducer";
import Buffering from "../components/General/Buffering";

const ParentContainer = Styled.div`
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  visibility: ${(props) => (props.toggle ? "visible" : "hidden")};
  background-color:${(props) => props.theme.backPri};
  backdrop-filter: blur(20px);
  display: flex;
  padding:30px;
  padding-bottom:0px;
  ${Mobile({ padding: "7px" })};
  box-sizing:border-box;
  &::-webkit-scrollbar{
    display:none;
  };
  align-items:flex-start;
  
`;

const Left = Styled.div`
  flex: 1;
  border-radius:10px;
  height:calc(100vh - 30px);
  overflow:scroll;
  &::-webkit-scrollbar{
    display:none;
  };
   ${Mobile({
     height: (props) =>
       props.isViewNoteActive ? "calc(50vh )" : "calc(100vh - 30px)",
   })};
`;

const Right = Styled.div`
  flex: 0.7;
  color: ${(item) => item.theme.fontPri};
  box-sizing:border-box;
  &::-webkit-scrollbar{
    display:none;
  };
  ${Mobile({ display: "none" })};
`;

const ActionContainer = Styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content:center;
  padding:20px;
  overflow:scroll;
  ${Mobile({ padding: "5px" })};
  box-sizing:border-box;
  &::-webkit-scrollbar{
    display:none;
  };
  `;
const ActionContainerMobile = Styled.div`
  flex-direction: column;
  align-items:center;
  justify-content:center;
  padding:20px;
  overflow:scroll;
  display:none;
  ${Mobile({ padding: "5px", display: "flex" })};
  box-sizing:border-box;
  &::-webkit-scrollbar{
    display:none;
  };
  `;
const NotesRenderMobile = Styled.div`
display:none;
position:absolute;
height:50vh;
width:100%; 
bottom:0px;
left:0px;
right:0px;
padding-left:5px;
padding-right:5px;
box-sizing:border-box;
${Mobile({ display: "flex" })};
`;
const NoteContainer = Styled.div`
width:100%;`;
const ButtonContainer = Styled.div`
  width:100%;
  display:flex;
  
  align-items:center;
  justify-content:center;
 

  `;

const Button = Styled.button`
  width:80%;
  padding:15px;
  background-color: ${(item) => item.theme.mainColor};
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size:16px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-weight:bolder;
  
  ${Mobile({ width: "100%" })};
  transition: transform 0.2s ease;
  &:active {
  transform: scale(0.95); /* Shrinks the button slightly */
  };
    `;

const CartContainer = Styled.div`
    flex:1;
    padding:5px;`;
const BuyContainer = Styled.div`
    padding:5px;
    flex:1;`;

const ReadDoc = () => {
  const dispatch = useDispatch();
  const activeNote = useSelector((state) => state.activenote);
  const { colorTheme, setNotification } = useContext(ContextProvider);
  const user = useSelector((state) => state.user.currentUser);
  const [docData, setDocData] = useState({});
  const [viewNote, setViewNote] = useState(false);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isLoggedIn = user ? true : false;

  const handleClick = (e) => {
    if (e.target.classList.contains("parent")) {
      dispatch(unsetActiveNote());
    }
  };
  const IconStyle = {
    height: "30px",
    width: "30px",
    paddingRight: "5px",
  };
  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      dispatch(unsetActiveNote());
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // const res = isLoggedIn
        //   ? await Axios.get(
        //       `${process.env.REACT_APP_API_URL}/api/v1/unit/getUnitByIdLoggedIn/${activeNote.docId}`,
        //       {
        //         headers: {
        //           Authorization: `Bearer ${user.access_token}`,
        //         },
        //       }
        //     )
        //   : await Axios.get(
        //       `${process.env.REACT_APP_API_URL}/api/v1/unit/getUnitByIdUnLogged/${activeNote.docId}`
        //     );
        const res = await Axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/unit/getUnitByIdUnLogged/${activeNote.docId}`
        );
        setDocData(res.data);
      } catch (error) {
        setNotification("failure", error.message);
      }
      setLoading(false);
    };
    activeNote.toggle && fetchData();
  }, [activeNote.toggle]);

  const handleToggleNote = () => {
    if (!user) {
      setNotification("failure", "Please login to continue");
      return;
    }
    setViewNote((item) => !item);
  };
  return (
    <ParentContainer
      theme={colorTheme}
      className="parent"
      onClick={(e) => handleClick(e)}
      toggle={activeNote.toggle}
    >
      <Left isViewNoteActive={viewNote} theme={colorTheme}>
        <ActionContainerMobile>
          <ButtonContainer>
            <CartContainer>
              <Button
                theme={colorTheme}
                onClick={() => dispatch(unsetActiveNote())}
              >
                <CloseRoundedIcon style={IconStyle} />
                Close
              </Button>
            </CartContainer>
            <BuyContainer>
              <Button theme={colorTheme} onClick={handleToggleNote}>
                <DescriptionRoundedIcon style={IconStyle} />
                Note
              </Button>
            </BuyContainer>
          </ButtonContainer>
        </ActionContainerMobile>
        {loading ? <Buffering /> : <RenderBlog docData={docData} />}
      </Left>
      <Right theme={colorTheme}>
        {viewNote ? (
          <ActionContainer>
            <Notes toggleNote={setViewNote} />
          </ActionContainer>
        ) : (
          <ActionContainer>
            <ButtonContainer>
              <CartContainer>
                <Button
                  theme={colorTheme}
                  onClick={() => dispatch(unsetActiveNote())}
                >
                  <CloseRoundedIcon style={IconStyle} />
                  Close
                </Button>
              </CartContainer>
              <BuyContainer>
                <Button theme={colorTheme} onClick={handleToggleNote}>
                  <DescriptionRoundedIcon style={IconStyle} />
                  Note
                </Button>
              </BuyContainer>
            </ButtonContainer>
          </ActionContainer>
        )}
      </Right>
      {viewNote && (
        <NotesRenderMobile>
          <NoteContainer>
            <Notes isMobile={true} toggleNote={setViewNote} />
          </NoteContainer>
        </NotesRenderMobile>
      )}
    </ParentContainer>
  );
};

export default ReadDoc;
