import React from "react";
import Styled from "styled-components";
import Home from "./Pages/Home";
import Liked from "./Pages/Liked";
import Mynotes from "./Pages/Mynotes";
import Purchased from "./Pages/Purchased";
import Cart from "./Pages/Cart.js";
import Orders from "./Pages/Orders.js";
import { useLocation } from "react-router-dom";
import NoteView from "./Pages/NoteView";
import Profile from "../components/Pages/Profile";
import Doc from "../pages/Doc";
import Note from "../pages/Note";

const Container = Styled.div`

flex:3.5;
/* top:60px; */
/* position:sticky; */
overflow:scroll;
height:calc(100vh - 60px);
/* background-color:red; */
&::-webkit-scrollbar{
  display:none;;
};
`;

const Content = () => {
  let Location = useLocation();

  Location = Location.pathname.split("/");

  let page = Location[1];

  return (
    <Container>
      {page === "home" && <Home />}
      {page === "liked" && <Liked />}
      {page === "mynotes" && <Mynotes />}
      {page === "purchased" && <Purchased />}
      {page === "note" && <NoteView />}
      {page === "cart" && <Cart />}
      {page === "orders" && <Orders />}
      {page === "profile" && <Profile />}
      {page === "doc" && <Doc />}
      {page === "notes" && <Note />}
    </Container>
  );
};

export default Content;
