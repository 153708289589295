import React, { useContext } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../App";

const Container = Styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: ${(props) => props.theme.fontPri};
  background-color: ${(props) => props.theme.backSec};

  

  h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  p, ul {
    margin-bottom: 15px;
  }

  ul {
    list-style: disc;
    padding-left: 20px;
  }

  a {
    color: #007BFF;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const CookiesPolicy = () => {
  const { colorTheme } = useContext(ContextProvider);

  return (
    <Container theme={colorTheme}>
      <h1>Cookies Policy Update</h1>
      <p>
        <strong>Effective Date:</strong> December 11, 2024
      </p>

      <p>
        This Cookies Policy outlines the use of cookies by Docshare, operated by
        Docshare ("Company", "We", "Us", or "Our"). Understanding the role of
        cookies and our use of them will help you make informed decisions about
        your interaction with our Website. This policy is aligned with our
        commitment to transparency and compliance with Google's requirements
        regarding cookie usage.
      </p>

      <h2>Definitions</h2>
      <ul>
        <li>
          <strong>Company:</strong> refers to Docshare, Located at Shri Jagdish
          Nagar, Sandi Road, Hardoi, Uttar Pradesh, India, 241001
        </li>
        <li>
          <strong>Cookies:</strong> are small data files placed on your device
          by websites, containing browsing history and user preferences.
        </li>
        <li>
          <strong>Website:</strong> is DocShare, accessible via{" "}
          <a href="https://docshare.in">https://docshare.in</a>.
        </li>
        <li>
          <strong>You:</strong> denotes the Website user, whether an individual
          or a legal entity.
        </li>
      </ul>

      <h2>Use of Cookies</h2>
      <p>
        Cookies on our Website can be categorized as "Session" or "Persistent":
      </p>
      <ul>
        <li>
          <strong>Session Cookies:</strong> are temporary and deleted when you
          close your browser.
        </li>
        <li>
          <strong>Persistent Cookies:</strong> remain on your device until they
          expire or are deleted manually.
        </li>
      </ul>
      <p>
        We utilize these cookies for essential functions and to enhance user
        experience:
      </p>
      <ul>
        <li>
          <strong>Necessary/Essential Cookies:</strong> These are crucial for
          accessing services on our Website, enabling features like
          authentication and account security. They do not require consent and
          are automatically placed on your device.
        </li>
        <li>
          <strong>Functionality Cookies:</strong> These cookies remember your
          preferences (like language settings) to provide a personalized
          experience.
        </li>
      </ul>

      <h2>Managing Cookies</h2>
      <p>
        You have control over the use of cookies on our Website. Adjust your
        browser settings to disable cookies if you prefer. However, this might
        affect your user experience and functionality availability on the
        Website. To manage cookies, follow guidance provided by your browser's
        support documentation:
      </p>
      <ul>
        <li>
          For the Chrome web browser, please visit this page from Google:{" "}
          <a href="https://support.google.com/accounts/answer/32050">
            https://support.google.com/accounts/answer/32050
          </a>
        </li>
        <li>
          For the Internet Explorer web browser, please visit this page from
          Microsoft:{" "}
          <a href="http://support.microsoft.com/kb/278835">
            http://support.microsoft.com/kb/278835
          </a>
        </li>
        <li>
          For the Firefox web browser, please visit this page from Mozilla:{" "}
          <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">
            https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
          </a>
        </li>
        <li>
          For the Safari web browser, please visit this page from Apple:{" "}
          <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
            https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
          </a>
        </li>
        <li>
          For browsers not listed here, please consult the respective
          documentation for cookie management instructions.
        </li>
      </ul>

      <h2>Further Information on Cookies</h2>
      <p>
        To understand more about cookies and their impact, you might want to
        research online resources that explain how cookies function and their
        common uses.
      </p>

      <h2>Contact Us</h2>
      <p>
        For any inquiries regarding this Cookies Policy, please reach out to us
        via email at{" "}
        <a href="mailto:docsharehelp@gmail.com">docsharehelp@gmail.com</a>.
      </p>
    </Container>
  );
};

export default CookiesPolicy;
