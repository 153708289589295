import React, { useContext, useEffect, useState } from "react";
import Styled from "styled-components";
import HomeCard from "../HomeCard";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { ContextProvider } from "../../App.js";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../../redux/loadReducer";
import { logout } from "../../redux/userReducer";
import NoItems from "../General/NoItems";
import Buffering from "../General/Buffering";
const Container = Styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Default: 4 columns */
  gap: 20px;
  padding: 20px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr); /* Tablet: 3 columns */
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* Tablet Portrait: 2 columns */
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr); /* Mobile: 1 column */
  }
`;

const Home = () => {
  const [docs, setDocs] = useState([]); // State to store documents
  const user = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  const { setNotification } = useContext(ContextProvider);
  const navigate = useNavigate();
  const loader = useSelector((state) => state.load.toggle);
  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading(true));
      try {
        const res = await Axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/profile/getLikedItems`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
            },
          }
        );
        console.log(res.data.liked);
        setDocs(res.data.liked);
      } catch (error) {
        if (error?.response?.status === 411) {
          setNotification("failure", "Sessions Exceeded! Please login again");
          dispatch(logout());
          navigate("/login");
          return;
        }
        setNotification("failure", error.response?.data?.message);
      }
      dispatch(setLoading(false));
    };
    fetchData();
  }, []);

  return (
    <>
      {loader ? (
        <Buffering />
      ) : docs.length > 0 ? (
        <Container>
          {docs.map((doc) => (
            <HomeCard
              key={doc.id._id}
              title={doc.id.docTitle}
              likeCount={doc.id.likeCount}
              img={doc.id.thumbnail}
              id={doc.id._id}
            />
          ))}
        </Container>
      ) : (
        <NoItems />
      )}
    </>
  );
};

export default Home;
