import React, { useContext } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../App";

const Container = Styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: ${(props) => props.theme.fontPri};
  background-color: ${(props) => props.theme.backSec};
  
  h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  p, ul {
    margin-bottom: 15px;
  }

  ul {
    list-style: disc;
    padding-left: 20px;
  }

  a {
    color: #007BFF;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const RefundPolicy = () => {
  const { colorTheme } = useContext(ContextProvider);
  return (
    <Container theme={colorTheme}>
      <h1>Return and Refund Policy</h1>
      <p>
        <strong>Last updated:</strong> December 11, 2024
      </p>

      <p>Thank you for shopping at DocShare.</p>

      <p>
        If, for any reason, You are not completely satisfied with a purchase, We
        invite You to review our policy on refunds and returns.
      </p>

      <p>
        The following terms are applicable for any products that You purchased
        with Us.
      </p>

      <h2>Interpretation and Definitions</h2>
      <h3>Interpretation</h3>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>

      <h3>Definitions</h3>
      <ul>
        <li>
          <strong>Company:</strong> refers to Docshare, Located at Shri Jagdish
          Nagar, Sandi Road, Hardoi, 241001, Uttar Pradesh, India
        </li>
        <li>
          <strong>Goods:</strong> Items offered for sale on the Service.
        </li>
        <li>
          <strong>Orders:</strong> A request by You to purchase Goods from Us.
        </li>
        <li>
          <strong>Service:</strong> The Website.
        </li>
        <li>
          <strong>Website:</strong> Nexgen AI, accessible from{" "}
          <a href="https://docshare.in">https://docshare.in</a>.
        </li>
        <li>
          <strong>You:</strong> The individual accessing or using the Service,
          or the company, or other legal entity on behalf of which such
          individual is accessing or using the Service.
        </li>
      </ul>

      <h2>Your Order Cancellation Rights</h2>
      <p>
        You are entitled to cancel Your Order within 14 days without giving any
        reason for doing so.
      </p>
      <p>
        The deadline for cancelling an Order is 14 days from the date on which
        You received the Goods or on which a third party you have appointed, who
        is not the carrier, takes possession of the product delivered.
      </p>
      <p>
        In order to exercise Your right of cancellation, You must inform Us of
        your decision by means of a clear statement. You can inform us of your
        decision by:
      </p>
      <ul>
        <li>
          Email:{" "}
          <a href="mailto:docsharehelp@gmail.com">docsharehelp@gmail.com</a>
        </li>
      </ul>
      <p>
        We will reimburse You no later than 14 days from the day on which We
        receive the returned Goods. We will use the same means of payment as You
        used for the Order, and You will not incur any fees for such
        reimbursement.
      </p>

      <h2>Conditions for Returns</h2>
      <p>
        In order for the Goods to be eligible for a return, please make sure
        that:
      </p>
      <ul>
        <li>The Goods were purchased in the last 14 days</li>
        <li>The Goods are in the original packaging</li>
      </ul>
      <p>The following Goods cannot be returned:</p>
      <ul>
        <li>
          The supply of Goods made to Your specifications or clearly
          personalized.
        </li>
        <li>
          The supply of Goods which according to their nature are not suitable
          to be returned, deteriorate rapidly or where the date of expiry is
          over.
        </li>
        <li>
          The supply of Goods which are not suitable for return due to health
          protection or hygiene reasons and were unsealed after delivery.
        </li>
        <li>
          The supply of Goods which are, after delivery, according to their
          nature, inseparably mixed with other items.
        </li>
      </ul>
      <p>
        We reserve the right to refuse returns of any merchandise that does not
        meet the above return conditions in our sole discretion.
      </p>
      <p>
        Only regular priced Goods may be refunded. Unfortunately, Goods on sale
        cannot be refunded. This exclusion may not apply to You if it is not
        permitted by applicable law.
      </p>

      <h2>Returning Goods</h2>
      <p>
        You are responsible for the cost and risk of returning the Goods to Us.
        You should send the Goods at the following address:
      </p>
      <p>
        Shri Jagdish Nagar, Sandi Road, Hardoi, 241001, Uttar Pradesh, India
      </p>
      <p>
        We cannot be held responsible for Goods damaged or lost in return
        shipment. Therefore, We recommend an insured and trackable mail service.
        We are unable to issue a refund without actual receipt of the Goods or
        proof of received return delivery.
      </p>

      <h2>Gifts</h2>
      <p>
        If the Goods were marked as a gift when purchased and then shipped
        directly to you, You'll receive a gift credit for the value of your
        return. Once the returned product is received, a gift certificate will
        be mailed to You.
      </p>
      <p>
        If the Goods weren't marked as a gift when purchased, or the gift giver
        had the Order shipped to themselves to give it to You later, We will
        send the refund to the gift giver.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about our Returns and Refunds Policy, please
        contact us:
      </p>
      <ul>
        <li>
          Email:{" "}
          <a href="mailto:docsharehelp@gmail.com">docsharehelp@gmail.com</a>
        </li>
      </ul>
    </Container>
  );
};

export default RefundPolicy;
