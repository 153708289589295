import React, { useContext } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../../App";
import { setActiveNote } from "../../redux/activeNoteReducer";
import { useDispatch } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
const Product = Styled.div`
width:80%;
flex:1;
border-radius:10px;
margin-bottom:10px;
padding:7px 12px;
display:flex;
align-items:center;
justify-content:center;
background-color:${(props) => props.theme.backSec};
`;

const ProductDetail = Styled.div`
flex:3;
display:flex;
align-items:center;

`;

const Img = Styled.img`
border-radius:10px;
/* height:150px; */
max-width:100px;
`;

const Detail = Styled.div`
flex:1;

padding:20px;
display:flex;
align-items:flex-start;
flex-direction:column;
justify-content:center;`;

const DetailText = Styled.span`
padding:4px;
font-size:20px;
`;
const Button = Styled.button`
  padding:10px;
  background-color: ${(item) => item.theme.mainColor};
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size:16px;
  display:flex;
  align-items:center;
  justify-content:center;
  transition: transform 0.2s ease;
  &:active {
  transform: scale(0.95); /* Shrinks the button slightly */
  };
`;
const BuyContainer = Styled.div`
  padding:5px;
  flex:1;
`;

const UnitItem = ({ thumbnail, unitTitle, subtitle, id }) => {
  const { colorTheme } = useContext(ContextProvider);
  const dispatch = useDispatch();
  const IconStyle = {
    height: "30px",
    width: "30px",
    paddingRight: "5px",
  };
  return (
    <Product theme={colorTheme} key={id}>
      <ProductDetail>
        <Img src={thumbnail} />
        <Detail>
          <DetailText>{unitTitle}</DetailText>
          <DetailText>{subtitle}</DetailText>
          <BuyContainer>
            <Button
              theme={colorTheme}
              onClick={() => dispatch(setActiveNote(id))}
            >
              <VisibilityIcon style={IconStyle} />
              Read Document
            </Button>
          </BuyContainer>
        </Detail>
      </ProductDetail>
    </Product>
  );
};

export default UnitItem;
