import React, { useContext, useState } from "react";
import Styled from "styled-components";
import PersonIcon from "@mui/icons-material/Person";
import { useDispatch, useSelector } from "react-redux";
import { toggleAccountBar } from "../redux/accountReducer";
import Noteshare from "../components/General/Icon";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { ContextProvider } from "../App";
import { useNavigate } from "react-router-dom";
import { Mobile } from "../responsive";
import AccountContainer from "../components/AccountContainer";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ShoppingBagRoundedIcon from "@mui/icons-material/ShoppingBagRounded";
import ThumbUpRoundedIcon from "@mui/icons-material/ThumbUpRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import PaymentRoundedIcon from "@mui/icons-material/PaymentRounded";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";

const LandingPageContainer = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.backPri};
  box-sizing:border-box;
  height:100vh;
  overflow:scroll;
  justify-content: space-between;
  &::-webkit-scrollbar{
      display:none;
};
`;

const Header = Styled.header`
  display: flex;
  justify-content: space-between;
  align-items:center;
  padding:0px 20px;
  box-sizing:border-box;
  height:60px;
  
  width: 100%;
  `;

const UserContainer = Styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  `;

const Middle = Styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: 50px; */
  /* background-color:red; */
  width:100vw;
  `;

const Heading = Styled.h1`
  font-size: 60px;
  font-weight: 700;
  color: ${(props) => props.theme.fontPri};
`;

const Footer = Styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding:5px 20px;
  box-sizing:border-box;
  background-color: ${(props) => props.theme.backSec};
  `;
const IconContainer = Styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

  `;

const InputContainer = Styled.div`
  flex: 9;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  background-color: ${(props) => props.theme.backSec};
  padding: 2px 20px;
  border-right: none;
`;

const Input = Styled.input`
  color: ${(props) => props.theme.fontPri};
  font-size: 18px;
  flex: 1;
  border: none;
  height: 30px;
  outline: none;
  background-color: ${(props) => props.theme.backSec};
  
  ${Mobile({ width: "90%" })};
`;

const SearchIconContainer = Styled.div`
  height: 50px;
  width: 50px;
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.backSec};
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding: 2px 4px;
`;
const FooterText = Styled.p`
  color: ${(props) => props.theme.fontSec};
  font-size: 14px;
  margin-right: 10px;
`;

const Center = Styled.div`
  flex: 2.5;
  display: flex;
  align-items: center;
  justify-content: center;
  width:50%;
  ${Mobile({ width: "80%" })};


`;
const Span = Styled.span`
font-size:14px;
color: ${(props) => props.theme.fontPri};
 &:hover {
    text-decoration: underline;
   
  };
  `;
const SpanBlue = Styled.span`

color: ${(props) => props.theme.mainColor};
`;

const Link = Styled.a`
  font-size: 14px;
  text-decoration: none;
   /* Ensure this is not commented */
  margin: 0 10px;

 
`;

const Left = Styled.div`
flex:1;`;
const Right = Styled.div`
flex:2;
display:flex;
align-items:center;
justify-content:flex-end;`;

const Comp = Styled.div`
color: ${(props) => props.theme.fontPri};
display:flex;
align-items:center;
justify-content:center;
margin:15px;
flex-direction:column;
${Mobile({ margin: "7px" })};
cursor:pointer;
`;
const Bottom = Styled.div`
width:50%;
${Mobile({ width: "100%" })};
`;

const BottomWrapper = Styled.div`

display:flex;
align-items:center;
justify-content:center;
flex-wrap:wrap;
padding:30px;`;

const TextFrame = Styled.div`
padding:7px;`;
const SpanFrame = Styled.span`
`;
const Frame = Styled.div`
  background-color: ${(props) => props.theme.backSec};
  display: flex;
  height:70px;
  width:70px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px;
  
`;

const LandingPage = () => {
  const { colorTheme } = useContext(ContextProvider);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const handleSearch = () => {
    navigate(`/home?search=${search}`);
  };

  const IconStyle = {
    height: "35px",
    width: "35px",
    cursor: "pointer",
    color: colorTheme.fontPri,
  };

  return (
    <LandingPageContainer theme={colorTheme}>
      <AccountContainer />
      <Header>
        <IconContainer onClick={() => navigate("/home")}>
          <Noteshare style={IconStyle} />
        </IconContainer>
        <UserContainer onClick={() => dispatch(toggleAccountBar())}>
          <PersonIcon style={IconStyle} />
        </UserContainer>
      </Header>
      <Middle>
        <Heading theme={colorTheme}>
          Doc<SpanBlue theme={colorTheme}>Share</SpanBlue>
        </Heading>
        <Center>
          <InputContainer theme={colorTheme}>
            <Input
              theme={colorTheme}
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
          </InputContainer>
          <SearchIconContainer theme={colorTheme}>
            <SearchRoundedIcon
              onClick={handleSearch}
              style={{
                height: "32px",
                width: "32px",
                cursor: "pointer",
                color: colorTheme.fontPri,
              }}
            />
          </SearchIconContainer>
        </Center>
        <Bottom>
          <BottomWrapper>
            <Comp onClick={() => navigate("/home")} theme={colorTheme}>
              <Frame theme={colorTheme}>
                <HomeRoundedIcon style={IconStyle}></HomeRoundedIcon>
              </Frame>
              <TextFrame>
                <SpanFrame> Home</SpanFrame>
              </TextFrame>
            </Comp>
            <Comp onClick={() => navigate("/liked")} theme={colorTheme}>
              <Frame theme={colorTheme}>
                <ThumbUpRoundedIcon style={IconStyle}></ThumbUpRoundedIcon>
              </Frame>
              <TextFrame>
                <SpanFrame> Liked</SpanFrame>
              </TextFrame>
            </Comp>
            <Comp onClick={() => navigate("/mynotes")} theme={colorTheme}>
              <Frame theme={colorTheme}>
                <DescriptionRoundedIcon
                  style={IconStyle}
                ></DescriptionRoundedIcon>
              </Frame>
              <TextFrame>
                <SpanFrame> Notes</SpanFrame>
              </TextFrame>
            </Comp>
            <Comp onClick={() => navigate("/cart")} theme={colorTheme}>
              <Frame theme={colorTheme}>
                <ShoppingCartRoundedIcon
                  style={IconStyle}
                ></ShoppingCartRoundedIcon>
              </Frame>
              <TextFrame>
                <SpanFrame> Cart</SpanFrame>
              </TextFrame>
            </Comp>
            <Comp onClick={() => navigate("/orders")} theme={colorTheme}>
              <Frame theme={colorTheme}>
                <ShoppingBagRoundedIcon
                  style={IconStyle}
                ></ShoppingBagRoundedIcon>
              </Frame>
              <TextFrame>
                <SpanFrame> Orders</SpanFrame>
              </TextFrame>
            </Comp>
            <Comp onClick={() => navigate("/purchased")} theme={colorTheme}>
              <Frame theme={colorTheme}>
                <PaymentRoundedIcon style={IconStyle}></PaymentRoundedIcon>
              </Frame>
              <TextFrame>
                <SpanFrame> Purchased</SpanFrame>
              </TextFrame>
            </Comp>
          </BottomWrapper>
        </Bottom>
      </Middle>
      <Footer theme={colorTheme}>
        <Left>
          <FooterText theme={colorTheme}>
            <span>&#169;</span> {new Date().getFullYear()} DocShare
          </FooterText>
        </Left>
        <Right>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flexEnd",
            }}
          >
            <Link href="https://docshare.in/privacy-policy" target="_blank">
              <Span theme={colorTheme}>Privacy Policy</Span>
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flexEnd",
            }}
          >
            <Link href="https://docshare.in/terms-conditions" target="_blank">
              <Span theme={colorTheme}>Terms & Conditions</Span>
            </Link>
          </div>
        </Right>
      </Footer>
    </LandingPageContainer>
  );
};

export default LandingPage;
