import React, { useContext } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../App";

const Container = Styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: ${(props) => props.theme.fontPri};
  background-color: ${(props) => props.theme.backSec};

  h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  p, ul {
    margin-bottom: 15px;
  }

  ul {
    list-style: disc;
    padding-left: 20px;
  }

  a {
    color: #007BFF;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const PrivacyPolicy = () => {
  const { colorTheme } = useContext(ContextProvider);
  return (
    <Container theme={colorTheme}>
      <h1>Privacy Policy Update</h1>
      <p>
        <strong>Effective Date:</strong> Dec 11, 2024
      </p>

      <p>
        This updated Privacy Policy outlines our commitment to safeguarding the
        privacy of our users while using our services ("Service"). It addresses
        the collection, use, and disclosure of personal information, emphasizing
        our compliance with the Limited Use Requirements under the Google API
        Services User Data Policy.
      </p>

      <h2>Our Commitment to Your Privacy</h2>
      <p>
        Your privacy is paramount to us. We collect and use your personal data
        solely to enhance your experience with our Service. By accessing our
        Service, you consent to our data practices as described in this policy.
        This document was crafted with the assistance of a Privacy Policy
        Generator to ensure clarity and compliance.
      </p>

      <h2>Understanding Terms</h2>
      <ul>
        <li>
          <strong>Account:</strong> refers to a unique user-created profile for
          accessing our Service.
        </li>
        <li>
          <strong>Affiliate:</strong> indicates an entity that has a significant
          control relationship with us, defined by majority ownership or voting
          rights.
        </li>
        <li>
          <strong>Company:</strong> refers to Docshare, Located at Shri Jagdish
          Nagar, Sandi Road, Hardoi, Uttar Pradesh, India, 241001
        </li>
        <li>
          <strong>Cookies:</strong> are data files placed on your device to
          collect internet usage information.
        </li>
        <li>
          <strong>Country:</strong>India, where our Company is based.
        </li>
        <li>
          <strong>Device:</strong> means any technology, such as a computer or
          smartphone, that can access our Service.
        </li>
        <li>
          <strong>Personal Data:</strong> is information that identifies you as
          an individual.
        </li>
        <li>
          <strong>Service Provider:</strong> denotes any entity that processes
          data on our behalf.
        </li>
        <li>
          <strong>Third-party Social Media Service:</strong> includes platforms
          like Google, Facebook, and Twitter that facilitate account creation
          and login.
        </li>
        <li>
          <strong>Usage Data:</strong> is automatically collected data about how
          the Service is accessed and used.
        </li>
        <li>
          <strong>Website:</strong> directs you to our Service, accessible at{" "}
          <a href="https://docshare.in">https://docshare.in</a>.
        </li>
        <li>
          <strong>You:</strong> refers to the individual using our Service or
          the entity on whose behalf the Service is used.
        </li>
      </ul>

      <h2>Data Collection and Use</h2>
      <p>
        We collect various information to provide and improve our Service. This
        includes:
      </p>
      <ul>
        <li>Personal Identifiers like email, name, and contact details.</li>
        <li>Usage Data detailing your interaction with our Service.</li>
      </ul>

      <h2>Limited Use Compliance</h2>
      <p>In adherence to the Limited Use Requirements, we commit to:</p>
      <ul>
        <li>
          Limiting data use to providing or enhancing features clearly evident
          in our user interface.
        </li>
        <li>
          Prohibiting data transfers except for feature provision, security,
          legal compliance, or during business restructuring, always with your
          explicit consent.
        </li>
        <li>
          Ensuring no human access to data without your affirmative consent,
          except for security or legal reasons, or in aggregated form for
          internal operations.
        </li>
        <li>
          Strictly forbidding data transfers to third parties for advertising,
          credit evaluation, or any unrelated purposes.
        </li>
      </ul>

      <h2>Data Retention and Security</h2>
      <p>
        We retain your Personal Data only as long as necessary for the purposes
        outlined in this Privacy Policy, adhering to legal and security
        standards. Despite employing robust measures to protect your data, no
        digital platform can guarantee absolute security.
      </p>

      <h2>Children's Privacy</h2>
      <p>
        Our Service is not intended for individuals under 13. We do not
        knowingly collect data from children and will take steps to delete such
        information if discovered.
      </p>

      <h2>External Links</h2>
      <p>
        Our Service may contain links to third-party sites. We are not
        responsible for the privacy practices of these external sites.
      </p>

      <h2>Policy Modifications</h2>
      <p>
        We may update this Privacy Policy to reflect changes in our practices.
        Significant changes will be communicated through our Service or via
        email.
      </p>

      <h2>Contact Information</h2>
      <p>
        For questions or concerns about our privacy practices, please contact us
        via email at{" "}
        <a href="mailto:docsharehelp@gmail.com">docsharehelp@gmail.com</a>.
      </p>

      <p>
        Your engagement with our Service following these updates constitutes
        acceptance of the revised Privacy Policy.
      </p>
    </Container>
  );
};

export default PrivacyPolicy;
