import React, { useContext } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../App";
import { Phone, Tablet } from "../responsive";
import { useNavigate } from "react-router-dom";

const Container = Styled.div`
/* border-radius: 10px;
  margin: 10px;
  cursor: pointer;
  flex: 1;
  min-width: 23%; 
  max-width: 23%; 

  @media (max-width: 1024px) {
    min-width: 45%; 
    max-width: 45%;
  }

  @media (max-width: 768px) {
    min-width: 95%; 
    max-width: 95%;
  } */

    border-radius: 10px;
  cursor: pointer;
  height: content-fit;
  width: 100%; /* Card will take full width of its grid cell */
`;

const Top = Styled.div`
background-color:${(props) => props.theme.backPri};
padding:10px;
border-radius:10px;

box-sizing:border-box;`;

const Bottom = Styled.div`
display:flex;
padding:10px;
`;

const Image = Styled.img`

border-radius:10px;
width:100%;`;

const Left = Styled.div`
flex:1;
display:flex;
align-items:center;
justify-content:center;`;

const Right = Styled.div`
flex:3;`;

const Icon = Styled.img`
object-fit:cover;
height:40px;
width:40px;
border-radius:50%;`;

const TopDiv = Styled.div``;
const BottomDiv = Styled.div``;
const Title = Styled.span`
color:${(props) => props.theme.fontPri};
font-size:16px;
font-weight:bold;`;

const Views = Styled.span`
color:${(props) => props.theme.fontSec};`;
const HomeCard = ({ img, title, likeCount, id }) => {
  const { colorTheme } = useContext(ContextProvider);
  const navigate = useNavigate();
  return (
    <Container theme={colorTheme} onClick={() => navigate(`/doc/${id}`)}>
      <Top theme={colorTheme}>
        <Image src={img} />
      </Top>
      <Bottom>
        <Left>
          <Icon src={img} />
        </Left>
        <Right>
          <TopDiv>
            <Title theme={colorTheme}>{title}</Title>
          </TopDiv>
          {/* <BottomDiv>
            <Views theme={colorTheme}>{`${likeCount} Likes`}</Views>
          </BottomDiv> */}
        </Right>
      </Bottom>
    </Container>
  );
};

export default HomeCard;
