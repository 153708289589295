import React, { useState, useContext, useEffect } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../../App";
import { Mobile } from "../../responsive";
import Button from "../General/Button";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import LinkIcon from "@mui/icons-material/Link";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LockIcon from "@mui/icons-material/Lock";
import EmailIcon from "@mui/icons-material/Email";
import PolicyIcon from "@mui/icons-material/Policy";
import RefundIcon from "@mui/icons-material/MonetizationOn";
import GavelIcon from "@mui/icons-material/Gavel";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import MailIcon from "@mui/icons-material/Mail";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import InputContainer from "../General/InputContainer";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import { useSelector } from "react-redux";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/loadReducer";
import { loginSuccess, logout } from "../../redux/userReducer";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import UseSystemTheme from "../../utils/UseSystemTheme";

const Container = Styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  background-color: ${(props) => `${props.theme.backSec}!important`}; 
`;

const Wrapper = Styled.form`
  width:30%;
  padding-left:20px;
  padding-bottom:20px;
  ${Mobile({ width: "90%" })};
`;

const ProfilePic = Styled.div`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #ccc;
  background-image: ${(props) => `url(${props.src})`};
  background-size: cover;
  background-position: center;
`;

const Links = Styled.div`
  a {
    margin-right: 10px;
    color: ${(props) => props.theme.fontPri};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ButtonContainer = Styled.div`
padding:10px;
display:flex;
align-items:center;
justify-content:flex-start;`;

const LoaderContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;
padding:5px;`;

const ButtonDiv = Styled.div``;

const Heading2Container = Styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.backPri};
  padding: 10px;
  margin-bottom: 10px;
  width: 95%;
  border-radius:20px;
  cursor: pointer;
`;

const Heading2 = Styled.h2`
  color: ${(props) => props.theme.fontPri};
  margin: 0;
  display: flex;
  align-items: center;
`;

const IconWrapper = Styled.span`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

const LinksContainer = Styled.div`
  padding: 10px;
  display:flex;
  border-radius:20px;
  transition:all 0.3s ease;
  &:hover{
    cursor:pointer;
    background-color:${(props) => props.theme.backPri};
  }
`;
const IconContainer = Styled.div`
  flex:1;
  display:flex;
  align-items:center;
  justify-content:center;`;

const LinkContainer = Styled.div`
  padding:10px;
  display:flex;
  align-items:center;
  justify-content:flex-start;
  flex:9;`;
const Span = Styled.span`
  color:${(props) => props.theme.fontPri};`;

const ProfilePicWrapper = Styled.div`
  display: flex;
  align-items: center;
  
`;

const UploadButton = Styled.label`
  margin-left: 20px;
  cursor: pointer;
  color: ${(props) => props.theme.fontPri};
`;

const Profile = () => {
  const dispatch = useDispatch();

  const [showLinks, setShowLinks] = useState(true);
  const [referring, setReferring] = useState(false);
  const [profilePic, setProfilePic] = useState("");
  const { colorTheme, theme, ToggleTheme, setNotification } =
    useContext(ContextProvider);
  const [showPersonalDetails, setShowPersonalDetails] = useState(false);
  const [showSystemSettings, setShowSystemSettings] = useState(true);
  const [showReferral, setShowReferral] = useState(false);
  const [updating, setUpdating] = useState(false);
  const user = useSelector((state) => state?.user?.currentUser);
  const [name, setName] = useState("");
  const loading = useSelector((state) => state?.load?.toggle);
  const accessToken = user?.access_token;
  const [refer, setRefer] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchProfile = async () => {
      dispatch(setLoading(true));

      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/profile/getProfile`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const profileData = res?.data;
        setName(profileData?.name);
        setProfilePic(profileData?.profilePic);
        const updatedUserDetail = {
          ...user,
          name: res?.data?.name,
          image: res?.data?.image,
          status: res?.data?.status,
          credits: res?.data?.credits,
        };
        dispatch(loginSuccess(updatedUserDetail));
      } catch (error) {
        if (error?.response?.status === 411) {
          setNotification("failure", "Sessions Exceeded! Please login again");
          dispatch(logout());
          navigate("/login");
          return;
        }
        setNotification(
          "failure",
          error.response?.data?.message || "Error fetching profile"
        );
      }
      dispatch(setLoading(false));
    };
    fetchProfile();
  }, []);

  const handleReferral = async (e) => {
    e.preventDefault();
    if (!refer) {
      setNotification("failure", "Please provide a mail");
      return;
    }
    setReferring(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/referral/sendReferralEmail`,

        { email: refer },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setNotification("success", "Referred Successfully");
      setRefer("");
    } catch (error) {
      setNotification("failure", "Error while referring!");
    }
    setReferring(false);
  };
  const handleProfilePicChange = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/upload/fileUpload`,

        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setProfilePic(res.data.url);
      const updatedUserDetail = {
        ...user,
        name: res?.data?.name,
        image: res?.data?.image,
        status: res?.data?.status,
        credits: res?.data?.credits,
      };
      dispatch(loginSuccess(updatedUserDetail));
    } catch (error) {
      if (error?.response?.status === 411) {
        setNotification("failure", "Sessions Exceeded! Please login again");
        dispatch(logout());
        navigate("/login");
        return;
      }
      setNotification(
        "failure",
        error.response?.data?.message || "Error updating profile"
      );
    }
  };

  const handleUpdateDetails = async (e) => {
    e.preventDefault();
    setUpdating(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/profile/updateProfile`,
        { name: name, image: profilePic },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const updatedUserDetail = {
        ...user,
        name: res?.data?.name,
        image: res?.data?.image,
        status: res?.data?.status,
        credits: res?.data?.credits,
      };
      dispatch(loginSuccess(updatedUserDetail));
      setNotification("success", "Profile updated successfully");
    } catch (error) {
      if (error?.response?.status === 411) {
        setNotification("failure", "Sessions Exceeded! Please login again");
        dispatch(logout());
        navigate("/login");
        return;
      }
      setNotification(
        "failure",
        error.response?.data?.message || "Error updating profile"
      );
    }
    setUpdating(false);
  };

  const IconStyle = {
    height: "35px",
    width: "35px",
    color: colorTheme?.fontPri,
  };
  const handleForgotPassword = () => {
    console.log("Forgot password link clicked");
  };

  return (
    <Container theme={colorTheme}>
      <Heading2Container
        theme={colorTheme}
        onClick={() => setShowPersonalDetails(!showPersonalDetails)}
      >
        <Heading2 theme={colorTheme}>
          <IconWrapper>
            <AccountCircleIcon />
          </IconWrapper>
          Profile
        </Heading2>

        {showPersonalDetails ? (
          <KeyboardArrowUpIcon style={IconStyle} />
        ) : (
          <KeyboardArrowDownIcon style={IconStyle} />
        )}
      </Heading2Container>
      {loading === false && showPersonalDetails && (
        <Wrapper onSubmit={handleUpdateDetails}>
          <ProfilePicWrapper>
            <ProfilePic src={user?.image} />
            <UploadButton theme={colorTheme} htmlFor="profile-pic">
              Update Photo
              <input
                type="file"
                id="profile-pic"
                style={{ display: "none" }}
                onChange={handleProfilePicChange}
              />
            </UploadButton>
          </ProfilePicWrapper>

          <LinksContainer theme={colorTheme}>
            <IconContainer>
              <MailIcon style={IconStyle} />
            </IconContainer>
            <LinkContainer>
              <Span theme={colorTheme}>{user?.email}</Span>
            </LinkContainer>
          </LinksContainer>

          <LinksContainer theme={colorTheme}>
            <IconContainer>
              <MonetizationOnIcon style={IconStyle} />
            </IconContainer>
            <LinkContainer>
              <Span theme={colorTheme}>Credits: {user?.credits}</Span>
            </LinkContainer>
          </LinksContainer>
          <LinksContainer theme={colorTheme}>
            <IconContainer>
              <AccountCircleIcon style={IconStyle} />
            </IconContainer>

            <LinkContainer>
              <InputContainer
                marginLess={true}
                value={name}
                type={"text"}
                setInput={setName}
              />
            </LinkContainer>
          </LinksContainer>
          <ButtonContainer>
            <ButtonDiv>
              <Button text={"Update"} isDisabled={updating} />
            </ButtonDiv>
            <LoaderContainer>{updating && <Loader />}</LoaderContainer>
          </ButtonContainer>
        </Wrapper>
      )}
      <Heading2Container
        theme={colorTheme}
        onClick={() => setShowReferral(!showReferral)}
      >
        <Heading2 theme={colorTheme}>
          <IconWrapper>
            <PersonAddRoundedIcon />
          </IconWrapper>
          Refer And Earn
        </Heading2>

        {showReferral ? (
          <KeyboardArrowUpIcon style={IconStyle} />
        ) : (
          <KeyboardArrowDownIcon style={IconStyle} />
        )}
      </Heading2Container>
      {loading === false && showReferral && (
        <Wrapper onSubmit={handleReferral}>
          <LinksContainer theme={colorTheme}>
            <IconContainer>
              <MailIcon style={IconStyle} />
            </IconContainer>
            <LinkContainer>
              <InputContainer
                marginLess={true}
                value={refer}
                placeholder={"email"}
                type={"text"}
                setInput={setRefer}
              />
            </LinkContainer>
          </LinksContainer>

          <ButtonContainer>
            <ButtonDiv>
              <Button text={"Refer"} isDisabled={updating} />
            </ButtonDiv>
            <LoaderContainer>{referring && <Loader />}</LoaderContainer>
          </ButtonContainer>
        </Wrapper>
      )}
      <Heading2Container
        theme={colorTheme}
        onClick={() => setShowSystemSettings(!showSystemSettings)}
      >
        <Heading2 theme={colorTheme}>
          <IconWrapper>
            <SettingsIcon />
          </IconWrapper>
          Settings
        </Heading2>
        {showSystemSettings ? (
          <KeyboardArrowUpIcon style={IconStyle} />
        ) : (
          <KeyboardArrowDownIcon style={IconStyle} />
        )}
      </Heading2Container>
      {showSystemSettings && (
        <Wrapper>
          <Links>
            <LinksContainer theme={colorTheme} onClick={ToggleTheme}>
              <IconContainer>
                {theme === "light" ? (
                  <DarkModeIcon style={IconStyle} />
                ) : (
                  <LightModeIcon style={{ ...IconStyle, color: "white" }} />
                )}
              </IconContainer>
              <LinkContainer>
                <Span theme={colorTheme}>Toggle Theme</Span>
              </LinkContainer>
            </LinksContainer>
            <LinksContainer
              theme={colorTheme}
              onClick={() => navigate("/password-reset")}
            >
              <IconContainer>
                <LockIcon style={IconStyle} />
              </IconContainer>
              <LinkContainer>
                <Span theme={colorTheme} onClick={handleForgotPassword}>
                  Forgot Password
                </Span>
              </LinkContainer>
            </LinksContainer>
            <LinksContainer
              theme={colorTheme}
              onClick={() => navigate("/verify-email")}
            >
              <IconContainer>
                <EmailIcon style={IconStyle} />
              </IconContainer>
              <LinkContainer>
                <Span theme={colorTheme}>
                  {user?.status === "verified"
                    ? "Email Verified"
                    : "Verify Email"}
                </Span>
              </LinkContainer>
            </LinksContainer>
          </Links>
        </Wrapper>
      )}

      {/* Links Section */}
      <Heading2Container
        theme={colorTheme}
        onClick={() => setShowLinks(!showLinks)}
      >
        <Heading2 theme={colorTheme}>
          <IconWrapper>
            <LinkIcon />
          </IconWrapper>
          Links
        </Heading2>
        {showLinks ? (
          <KeyboardArrowUpIcon style={IconStyle} />
        ) : (
          <KeyboardArrowDownIcon style={IconStyle} />
        )}
      </Heading2Container>
      {showLinks && (
        <Wrapper>
          <Links theme={colorTheme}>
            <LinksContainer
              theme={colorTheme}
              onClick={() => navigate("/privacy-policy")}
            >
              <IconContainer>
                <PolicyIcon style={IconStyle} />
              </IconContainer>
              <LinkContainer>
                <Span theme={colorTheme}>Privacy Policy</Span>
              </LinkContainer>
            </LinksContainer>
            <LinksContainer
              theme={colorTheme}
              onClick={() => navigate("/refund-policy")}
            >
              <IconContainer>
                <RefundIcon style={IconStyle} />
              </IconContainer>
              <LinkContainer>
                <Span theme={colorTheme}>Refund Policy</Span>
              </LinkContainer>
            </LinksContainer>
            <LinksContainer
              theme={colorTheme}
              onClick={() => navigate("/terms-conditions")}
            >
              <IconContainer>
                <GavelIcon style={IconStyle} />
              </IconContainer>
              <LinkContainer>
                <Span theme={colorTheme}>Terms & Conditions</Span>
              </LinkContainer>
            </LinksContainer>
            <LinksContainer
              theme={colorTheme}
              onClick={() => navigate("/cookies-policy")}
            >
              <IconContainer>
                <GavelIcon style={IconStyle} />
              </IconContainer>
              <LinkContainer>
                <Span theme={colorTheme}>Cookies Policy</Span>
              </LinkContainer>
            </LinksContainer>
          </Links>
        </Wrapper>
      )}
    </Container>
  );
};

export default Profile;
