import React, { useContext, useEffect, useState } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../App";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../redux/loadReducer";
import { logout } from "../redux/userReducer";
import { Mobile, Phone } from "../responsive";
import axios from "axios";
import RenderNoteIndiv from "../components/RenderNoteIndiv"; // Assuming you have this component for rendering the note.
import Buffering from "../components/General/Buffering";
import NoItems from "../components/General/NoItems";
// Styled-components
const Container = Styled.div`
  padding-left:20px;
  padding-top:5px;
  padding-right:20px;
  color: ${(item) => item.theme.fontPri};
  ${Mobile({ paddingLeft: "10px", paddingTop: "10px", paddingRight: "10px" })};
  
`;

const Wrapper = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  align-items:flex-start;
  ${Phone({ flexDirection: "column" })};
`;

const Left = Styled.div`
  flex: 1;
  display:flex;
  align-items:center;
  width:100%;
  
  ${Mobile({ justifyContent: "center", width: "100%" })};
`;

const Note = () => {
  const { colorTheme, setNotification } = useContext(ContextProvider);
  const loader = useSelector((state) => state.load.toggle);
  const [docData, setDocData] = useState(null);
  const location = useLocation();
  const noteId = location.pathname.split("/")[2]; // Extracting note ID from the URL
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchNoteById = async () => {
      dispatch(setLoading(true));

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/notes/getNotesById/${noteId}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`, // Attach bearer token
            },
          }
        );
        let NoteData = response.data.contents.map((item) => {
          return {
            type: item.id.type,
            content: item.id.content,
          };
        });

        setDocData({
          NoteData,
          docTitle: response.data.relatedTo.docTitle,
          subtitle: response.data.relatedTo.subtitle,
        });
      } catch (error) {
        if (error?.response?.status === 411) {
          setNotification("failure", "Sessions Exceeded! Please login again");
          dispatch(logout());
          navigate("/login");
          return;
        }
        setNotification("failure", error?.response?.data?.message);
      }
      dispatch(setLoading(false));
    };

    fetchNoteById();
  }, [noteId]);

  return (
    <Container theme={colorTheme}>
      {loader === false ? (
        docData ? (
          <Wrapper theme={colorTheme}>
            <Left theme={colorTheme}>
              <RenderNoteIndiv blogData={{ contents: docData }} />
            </Left>
          </Wrapper>
        ) : (
          <NoItems />
        )
      ) : (
        <Buffering />
      )}
    </Container>
  );
};

export default Note;
